import React, {Fragment, useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useDimension} from '../../Hooks/AppHooks';
import CheckoutPanel from './CheckoutPanel';
import GiveBackPromo from './GiveBackPromo';
import Support from './Support';
import Modal from './Modal';
import {Actions, Context, NavActions} from '../../Contexts/AppContext';
import {
  Block,
  Color,
  FlexCol,
  FlexColCenter,
  FlexRow,
  FontSize,
  fstyles,
} from '../../Components/Widget';
import {Cross, Cube, Edit, LogoTungrp} from '../../Components/SvgIcon';
import CartItem, {CART_ITEM_TYPE} from '../../Components/cartItem/CartItem';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import Checkbox from '../../Components/Checkbox';
import {getFormatCartItems, getFormatPrice} from '../../Utils/ProductUtil';
import CustomNav from '../../Components/CustomNav';
import * as L from '../../Utils/Lang';
import {
  getConsumptionRewards,
  getMemberLevelData,
} from '../../Utils/PromotionsUtil';
import {
  MEMBERSHIP_LEVEL,
  MEMBERSHIP_LEVEL_DISPLAY,
  MEMBERSHIP_LEVEL_THRESHOLDS,
} from '../../Domain/Member';
import BackButton from '../../Components/BackButton';

const InfoBlock = props => {
  const {title = '', hint = '', items = []} = props;
  return (
    <InfoBlockStyles>
      <div className="title">{title}</div>
      {hint && <div className="hint">{hint}</div>}
      <div className="items">
        {items.map((i, index) => (
          <div className="item" key={index}>
            <Cube fill={Color.mainDark} size={16} style={{margin: '0 5px'}} />
            <div className="label">{i.label}</div>
            {i.number && (
              <div className="number">{getFormatPrice(i.number)}</div>
            )}
          </div>
        ))}
      </div>
    </InfoBlockStyles>
  );
};

const InfoBlockStyles = styled.div`
  padding: 20px;
  & > .title {
    font-weight: bold;
    font-size: 16px;
  }
  & > .hint {
    margin-top: 2px;
    font-weight: 300;
    color: ${Color.mainDark_70};
  }
  & > .items {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    & > .item {
      font-weight: 500;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      align-items: center;
      & > .label {
        margin-right: 4px;
      }
      & > .number {
        color: ${Color.gold};
      }
    }
  }
`;

const MobileCartPage = props => {
  const {
    cart,
    mobile,
    modal,
    tablet,
    disabledCheckout,
    disabledCheckoutByOverMax,
  } = props;
  const [isNextBuy, setIsNextBuy] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const isHasCartItems = cart?.items.length > 0;
  const [memberLevelData, setMemberLevelData] = React.useState(null);
  const app = React.useContext(Context);
  const user = app.currentUser;

  const [orderHistories, setOrderHistories] = useState(null);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    const fetchOrderHistories = async () => {
      const resp = await Actions.fetchOrderHistories();
      setOrderHistories(resp);
    };
    fetchOrderHistories().then(() => 0);
  }, [user]);

  const grandTotalHistories = useMemo(() => {
    if (!orderHistories) {
      return 0;
    }
    return orderHistories.histories.reduce((acc, cur) => {
      acc += cur.price;
      return acc;
    }, 0);
  }, [orderHistories]);

  React.useEffect(() => {
    if (user) {
      let data = getMemberLevelData({
        price: cart.price_detail.price,
        level: user.vip_level,
        grandTotal: grandTotalHistories,
      });
      setMemberLevelData(data);
    }
  }, [user, setMemberLevelData, cart.price_detail.price, grandTotalHistories]);

  const items = useMemo(() => {
    if (!user) {
      return [];
    }
    let result = [];
    getConsumptionRewards(cart.price_detail.price, user.vip_level) !== 0 &&
      result.push({
        label: `${L.d(
          MEMBERSHIP_LEVEL_DISPLAY[user.vip_level],
        )}消費回饋 ${MEMBERSHIP_LEVEL_THRESHOLDS.find(
          c => c.key === user.vip_level,
        ).reward_ratio * 100}%`,
        number: getConsumptionRewards(cart.price_detail.price, user.vip_level),
      });
    const rewards = memberLevelData?.active?.rewards || 0;
    rewards !== 0 &&
      Object.values(MEMBERSHIP_LEVEL).indexOf(memberLevelData?.active?.key) >
        Object.values(MEMBERSHIP_LEVEL).indexOf(user.vip_level) &&
      result.push({
        label: `升級${L.d(
          MEMBERSHIP_LEVEL_DISPLAY[memberLevelData?.active.key],
        )}回饋`,
        number: rewards,
      });
    return result;
  }, [
    cart.price_detail.price,
    memberLevelData?.active?.key,
    memberLevelData?.active?.rewards,
    user,
  ]);

  return (
    <MobileWrapper>
      {!isEditing && (
        <FlexCol style={{flex: 1}}>
          <Header>
            <Cross
              className="cross"
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />

            <div className="tabs">
              <label
                className={!isNextBuy ? 'active' : ''}
                onClick={() => {
                  setIsNextBuy(false);
                }}>
                購物車({`${cart?.items.length}`})
              </label>

              {/*FIXME: @guychienll future feature comment first*/}
              {/*<label*/}
              {/*  className={isNextBuy ? 'active' : ''}*/}
              {/*  onClick={() => {*/}
              {/*    setIsNextBuy(true);*/}
              {/*  }}>*/}
              {/*  下次再買(0)*/}
              {/*</label>*/}
            </div>

            <Edit
              className="edit"
              onClick={() => {
                setIsEditing(true);
              }}
            />
          </Header>
          {isHasCartItems && !isNextBuy && (
            <GiveBackPromo mobile={mobile} priceDetail={cart.price_detail} />
          )}
          {isHasCartItems && !isNextBuy && (
            <FlexCol style={{padding: 20}}>
              {cart.items.map((item, index) => (
                <CartItem
                  style={{marginBottom: 20}}
                  key={index}
                  items={cart.items}
                  type={CART_ITEM_TYPE.MOBILE}
                  item={item}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              ))}
            </FlexCol>
          )}
          {!isHasCartItems && !isNextBuy && (
            <FlexColCenter style={{flex: 1}}>
              <div>您的購物車中沒有商品，趕快去逛逛吧！</div>
            </FlexColCenter>
          )}
          {isNextBuy && (
            <FlexColCenter style={{flex: 1}}>
              <div>沒有下次再買的商品</div>
            </FlexColCenter>
          )}
          {isHasCartItems && !isNextBuy && (
            <FlexCol style={{paddingBottom: 130}}>
              <div style={{background: Color.mainDark_03, height: 12}} />

              {cart.price_detail.target_price_discount_value !== 0 && (
                <InfoBlock
                  title="已符合"
                  items={[
                    {label: L.d(cart.price_detail.target_price_discount_title)},
                  ]}
                />
              )}
              <div style={{background: Color.mainDark_03, height: 12}} />
              {user &&
                (getConsumptionRewards(
                  cart.price_detail.price,
                  user.vip_level,
                ) > 0 ||
                  memberLevelData?.active.rewards > 0) && (
                  <InfoBlock
                    title="此訂單可獲得的回饋"
                    hint="將在訂單完成七天後匯入會員帳號"
                    items={items}
                  />
                )}
              <div style={{background: Color.mainDark_03, height: 12}} />

              {disabledCheckout && (
                <Block style={{margin: 0}} error>
                  購物車中有商品的庫存不足，請在結帳前移除或調整商品數量
                </Block>
              )}

              {disabledCheckoutByOverMax && (
                <Block style={{margin: '10px 0'}} error>
                  結帳金額上限為 NT$200,000，請將部份商品分批購買
                </Block>
              )}

              <CheckoutPanel
                modal={modal}
                cart={cart}
                mobile={mobile}
                disabledCheckout={disabledCheckout}
                disabledCheckoutByOverMax={disabledCheckoutByOverMax}
                onConfirm={() => {
                  if (user) {
                    NavActions.navigate('/checkout');
                  } else {
                    NavActions.navigate('/login?prevPage=cart');
                  }
                }}
              />
            </FlexCol>
          )}
        </FlexCol>
      )}

      {isEditing && (
        <CartEditingView
          cart={cart}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
        />
      )}
    </MobileWrapper>
  );
};

const DesktopCartPage = props => {
  const {
    cart,
    mobile,
    modal,
    disabledCheckout,
    disabledCheckoutByOverMax,
  } = props;
  const app = React.useContext(Context);
  const user = app.currentUser;

  return (
    <Wrapper>
      <div className="items">
        {disabledCheckout && (
          <Block style={{margin: '10px 0'}} error>
            購物車中有商品的庫存不足，請在結帳前移除或調整商品數量
          </Block>
        )}
        {disabledCheckoutByOverMax && (
          <Block style={{margin: '10px 0'}} error>
            結帳金額上限為 NT$200,000，請將部份商品分批購買
          </Block>
        )}
        <h2>購物車{` ( ${cart.items.length} )`}</h2>
        {cart.items.map((item, index) => (
          <CartItem
            key={index}
            items={cart.items}
            style={{margin: '24px 0px'}}
            type={CART_ITEM_TYPE.DESKTOP}
            item={item}
          />
        ))}
        {cart.items.length <= 0 && (
          <FlexColCenter style={{margin: '64px 0'}}>
            您的購物車中沒有商品，趕快去逛逛吧！
          </FlexColCenter>
        )}
      </div>
      <div className="actions">
        <GiveBackPromo mobile={mobile} priceDetail={cart.price_detail} />

        {cart.items.length > 0 && (
          <CheckoutPanel
            cart={cart}
            mobile={mobile}
            modal={modal}
            disabledCheckout={disabledCheckout}
            disabledCheckoutByOverMax={disabledCheckoutByOverMax}
            onConfirm={() => {
              if (user) {
                NavActions.navigate('/checkout');
              } else {
                NavActions.navigate('/login?prevPage=checkout');
              }
            }}
          />
        )}
        <Support mobile={mobile} style={{marginTop: 32}} />
      </div>
    </Wrapper>
  );
};

const CartPage = () => {
  // HINT: apply this breakpoint in special case , do not change this
  const {dimension} = useDimension();
  const mobile = dimension.innerWidth <= 640;
  const tablet = !mobile && dimension.innerWidth <= 1024;
  const smallDevice = mobile || tablet;
  const modalRef = React.useRef();
  const app = useContext(Context);
  const {cart} = app;

  const disabledCheckout = useMemo(() => {
    if (!cart) {
      return true;
    }
    return !!cart.items.find(i => {
      return (
        i.quantity > i.variant.stock ||
        !i.variant.product.published ||
        i.variant.stock <= 0
      );
    });
  }, [cart]);

  const disabledCheckoutByOverMax = useMemo(() => {
    if (!cart) {
      return true;
    }
    return cart.price_detail.price > 200000;
  }, [cart]);

  if (!dimension || !cart) {
    return null;
  }

  return (
    <Fragment>
      {tablet || mobile ? (
        <MobileCartPage
          disabledCheckoutByOverMax={disabledCheckoutByOverMax}
          disabledCheckout={disabledCheckout}
          modal={modalRef}
          tablet={tablet}
          mobile={mobile}
          cart={cart}
        />
      ) : (
        <Fragment>
          <CustomNav
            navType="solid"
            left={
              <BackButton
                title="繼續購物"
                onClick={() => {
                  NavActions.navigate('/home');
                }}
              />
            }
            middle={<LogoTungrp w={28} h={38} />}
          />

          <DesktopCartPage
            disabledCheckoutByOverMax={disabledCheckoutByOverMax}
            disabledCheckout={disabledCheckout}
            modal={modalRef}
            mobile={mobile}
            cart={cart}
          />
        </Fragment>
      )}

      <Modal
        mobile={smallDevice}
        getInstance={inst => {
          modalRef.current = inst;
        }}
      />
    </Fragment>
  );
};

const EditButtons = props => {
  const {onNextBuyClick, onDeleteClick, disabled} = props;
  return (
    <FlexRow
      style={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0,
        backgroundColor: 'white',
        zIndex: 100,
      }}>
      {/*FIXME: @guychienll future feature comment first*/}
      {/*<RectButton*/}
      {/*  hoverEffect={false}*/}
      {/*  isDisabled={disabled}*/}
      {/*  text="下次再買"*/}
      {/*  skin={BUTTON_SKIN.GOLD}*/}
      {/*  onClick={onNextBuyClick}*/}
      {/*  style={{width: '50%'}}*/}
      {/*/>*/}
      <RectButton
        hoverEffect={false}
        isDisabled={disabled}
        text="移除"
        skin={BUTTON_SKIN.RED}
        onClick={onDeleteClick}
        style={{width: '100%'}}
      />
    </FlexRow>
  );
};

// only use for mobile (CartEditingView)
const CartEditingView = props => {
  const {cart, isEditing, setIsEditing} = props;
  const [selectList, setSelectList] = useState([]);

  const onChange = e => {
    const {value} = e.target;
    const valueOfTarget = parseInt(value);
    const idxOfTarget = selectList.indexOf(valueOfTarget);
    if (idxOfTarget <= -1) {
      setSelectList([...selectList, valueOfTarget]);
    } else {
      setSelectList(selectList.filter(id => id !== valueOfTarget));
    }
  };

  return (
    <div>
      <Header>
        <div
          onClick={() => {
            setIsEditing(false);
          }}
          style={{
            fontSize: FontSize.meta,
            color: Color.mainDark_70,
            position: 'absolute',
            right: 20,
          }}>
          完成
        </div>
        編輯購物車
      </Header>

      {cart.items.map((item, index) => {
        return (
          <FlexRow
            key={index}
            style={{
              padding: 20,
              paddingLeft: 0,
            }}>
            <Checkbox
              size={16}
              style={{margin: '0 12px'}}
              onChange={onChange}
              id={item.variant.id}
              checked={selectList.includes(item.variant.id)}
            />
            <CartItem
              style={{flex: 1}}
              items={cart.items}
              type={CART_ITEM_TYPE.MOBILE}
              item={item}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </FlexRow>
        );
      })}
      <EditButtons
        disabled={selectList.length === 0}
        onNextBuyClick={async () => {
          const formatCartItems = getFormatCartItems(cart.items);
          const nextCartItems = formatCartItems
            .map(item => {
              if (selectList.includes(item.variant_id)) {
                return null;
              } else {
                return item;
              }
            })
            .filter(item => item !== null);
          await Actions.updateCart({items: nextCartItems});
        }}
        onDeleteClick={async () => {
          const formatCartItems = getFormatCartItems(cart.items);
          const nextCartItems = formatCartItems
            .map(item => {
              if (selectList.includes(item.variant_id)) {
                return null;
              } else {
                return item;
              }
            })
            .filter(item => item !== null);
          await Actions.updateCart({items: nextCartItems});
        }}
      />
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 20px 50px 40px 50px;

  & > .items {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 130px;
  }

  & > .actions {
    width: 400px;
  }
`;

const MobileWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

const Header = styled.div`
  position: fixed;
  top: var(--scroll-bar-height);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--navbar-height);
  background-color: ${Color.mainWhite};
  border-bottom: 2px solid ${Color.mainDark_10};
  z-index: 10;
  & > .cross {
    position: absolute;
    left: 10px;
    cursor: pointer;
  }

  & > .edit {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  & > .done {
    position: absolute;
    right: 10px;
    top: 13px;
  }

  & > .tabs {
    height: 100%;
    width: 45%;
    display: flex;
    ///*FIXME: @guychienll future feature comment first*/
    justify-content: center;
    align-items: center;
    ${fstyles.text14}
    & label.active {
      border-bottom: 2px solid ${Color.mainDark};
    }

    & label {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 2px solid transparent;
      transform: translate(0px, 2px); /* offset of border */
    }
  }
`;

export default CartPage;
