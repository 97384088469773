import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import {Context} from '../../Contexts/AppContext';
import {
  getMemberLevelData,
  getConsumptionRewards,
} from '../../Utils/PromotionsUtil';
import * as L from '../../Utils/Lang';
import {
  MEMBERSHIP_LEVEL,
  MEMBERSHIP_LEVEL_DISPLAY,
  MEMBERSHIP_LEVEL_THRESHOLDS,
} from '../../Domain/Member';
import {getFormatPrice} from '../../Utils/ProductUtil';
import {Actions} from '../../Contexts/AppContext';

function GiveBack(props) {
  const {price, style = {}} = props;
  const [memberLevelData, setMemberLevelData] = React.useState(null);
  const app = React.useContext(Context);
  const user = app.currentUser;

  const [orderHistories, setOrderHistories] = useState(null);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    const fetchOrderHistories = async () => {
      const resp = await Actions.fetchOrderHistories();
      setOrderHistories(resp);
    };
    fetchOrderHistories().then(() => 0);
  }, [user]);

  const grandTotalHistories = useMemo(() => {
    if (!orderHistories) {
      return 0;
    }
    return orderHistories.histories.reduce((acc, cur) => {
      acc += cur.price;
      return acc;
    }, 0);
  }, [orderHistories]);

  React.useEffect(() => {
    if (user) {
      let data = getMemberLevelData({
        price: price,
        level: user.vip_level,
        grandTotal: grandTotalHistories,
      });
      setMemberLevelData(data);
    }
  }, [user, setMemberLevelData, price, grandTotalHistories]);

  const rewards = memberLevelData?.active?.rewards || 0;

  if (!memberLevelData || !memberLevelData?.active) {
    return null;
  }

  if (getConsumptionRewards(price, user.vip_level) <= 0 && rewards <= 0) {
    return null;
  }

  return (
    <GiveBackWrapper style={style}>
      {getConsumptionRewards(price, user.vip_level) !== 0 && (
        <div className="row">
          <div className="label">
            {L.d(MEMBERSHIP_LEVEL_DISPLAY[user.vip_level])}消費回饋{' '}
            {MEMBERSHIP_LEVEL_THRESHOLDS.find(c => c.key === user.vip_level)
              .reward_ratio * 100}{' '}
            %
          </div>
          <div className="price">
            NT {getFormatPrice(getConsumptionRewards(price, user.vip_level))}
          </div>
        </div>
      )}

      {Object.values(MEMBERSHIP_LEVEL).indexOf(memberLevelData.active.key) >
        Object.values(MEMBERSHIP_LEVEL).indexOf(user.vip_level) &&
        rewards !== 0 && (
          <div className="row">
            <div className="label">
              升級{L.d(MEMBERSHIP_LEVEL_DISPLAY[memberLevelData.active.key])}
              回饋
            </div>
            <div className="price">NT {getFormatPrice(rewards)}</div>
          </div>
        )}

      <div
        style={{
          height: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          marginBottom: 12,
        }}
      />

      <div className="hint">訂單完成七天後匯入會員帳號</div>
    </GiveBackWrapper>
  );
}

const GiveBackWrapper = styled.div`
  padding: 12px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  background-color: white;
  border-top: 2px solid #141414;

  & > .row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & > .label {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      color: rgba(20, 20, 20, 0.7);
      flex: 1;
    }

    & > .price {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      color: #cca75c;
    }
  }

  & > .hint {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.67;
    text-align: center;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default GiveBack;
