import React, {useCallback, useContext, useMemo} from 'react';
import styled from 'styled-components';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import GiveBack from './GiveBack';
import * as AppContext from '../../Contexts/AppContext';
import {Actions, Context} from '../../Contexts/AppContext';
import {getFormatPrice, getOriginPriceSubtotal} from '../../Utils/ProductUtil';
import * as L from '../../Utils/Lang';
import * as SvgIcon from '../../Components/SvgIcon';
import {
  CheckBox,
  Color,
  FlexRow,
  UnderlineButton,
} from '../../Components/Widget';
import CreditPanel from './CreditPanel';
import {getIsShowUseGiveBackBar, summaryOfPoints} from '../../Utils/appLogic';
import {useDimension} from '../../Hooks/AppHooks';
import {
  PrivacyPolicySections,
  TermsOfOnlineTransactionSections,
} from '../../Domain/Help';
import Alert from '../../Components/Modal/Alert';

function CheckoutPanel(props) {
  const {
    modal,
    cart,
    values,
    setValues,
    isCheckout = false,
    onConfirm = () => 0,
    loading,
    disabledCheckout,
    disabledCheckoutByOverMax,
  } = props;
  const app = useContext(Context);
  // HINT: apply this breakpoint in special case , do not change this
  const {dimension} = useDimension();
  const mobile = dimension.innerWidth <= 640;
  const tablet = !mobile && dimension.innerWidth <= 1024;
  const smallDevice = mobile || tablet;

  const totalUsedPoints = useMemo(() => {
    return app.isUsePoints
      ? app.points.useBirthGiftPoints + app.points.useRebatePoints
      : 0;
  }, [
    app.isUsePoints,
    app.points.useBirthGiftPoints,
    app.points.useRebatePoints,
  ]);

  const isShowUseGiveBackBar = useMemo(
    () =>
      app.currentUser &&
      getIsShowUseGiveBackBar({
        rebatePoints: app.currentUser.rebate_points,
        birthGiftPoints: app.currentUser.birth_gift_points,
      }),
    [app.currentUser],
  );

  const subtotalWithoutPromotion = useMemo(() => {
    return (
      app.cart.subtotal - app.cart.price_detail.target_price_discount_value
    );
  }, [app.cart.price_detail.target_price_discount_value, app.cart.subtotal]);

  const getMaxCanUseBirthGiftPoints = useCallback(() => {
    if (!app.currentUser) {
      return 0;
    }
    const currentUseRebatePoint = app.points.useRebatePoints || 0;
    const maximumOfTotalBirthGift =
      Math.floor(summaryOfPoints(app.currentUser.birth_gift_points) / 500) *
      500;
    const sum =
      maximumOfTotalBirthGift >= subtotalWithoutPromotion
        ? Math.floor((subtotalWithoutPromotion - currentUseRebatePoint) / 500) *
          500
        : maximumOfTotalBirthGift;
    return sum < 0 ? 0 : sum;
  }, [app.currentUser, app.points.useRebatePoints, subtotalWithoutPromotion]);

  const getMaxCanUseRebatePoints = useCallback(() => {
    if (!app.currentUser) {
      return 0;
    }
    const maximumOfTotalRebate =
      Math.floor(summaryOfPoints(app.currentUser.rebate_points) / 1000) * 1000;
    const sum =
      maximumOfTotalRebate >=
      subtotalWithoutPromotion - getMaxCanUseBirthGiftPoints()
        ? Math.floor(
            (subtotalWithoutPromotion - getMaxCanUseBirthGiftPoints()) / 1000,
          ) * 1000
        : maximumOfTotalRebate;
    return sum < 0 ? 0 : sum;
  }, [app.currentUser, getMaxCanUseBirthGiftPoints, subtotalWithoutPromotion]);

  const calculate = useCallback(async () => {
    await Actions.setPoints({
      useBirthGiftPoints: getMaxCanUseBirthGiftPoints(),
      useRebatePoints: getMaxCanUseRebatePoints(),
    });
  }, [getMaxCanUseBirthGiftPoints, getMaxCanUseRebatePoints]);

  const onTogglePoint = async () => {
    try {
      Actions.setLoading(true);
      if (app.isUsePoints === false) {
        await calculate();
      }
      await Actions.setIsUsePoints(!app.isUsePoints);
    } catch (e) {
      await Actions.setIsUsePoints(false);
    } finally {
      Actions.setLoading(false);
    }
  };

  const openDetailModal = () => {
    modal.current.open(
      <DetailModalContent
        isShowUseGiveBackBar={isShowUseGiveBackBar}
        totalUsedPoints={totalUsedPoints}
        onTogglePoint={onTogglePoint}
        mobile={smallDevice}
        cart={cart}
        onClose={() => modal.current.close()}
      />,
    );
  };

  const openCreditPanel = () => {
    modal.current.open(
      <CreditPanel
        discount={cart.price_detail.target_price_discount_value}
        subtotal={cart.price_detail.subtotal}
        mobile={smallDevice}
        onClose={() => {
          modal.current.close();
        }}
      />,
    );
  };

  if (smallDevice) {
    return (
      <MobileWrapper>
        {isShowUseGiveBackBar && (
          <div className="discount">
            <FlexRow style={{marginBottom: 5}}>
              <CheckBox isOn={app.isUsePoints} onClick={onTogglePoint} />

              <div style={{flex: 1, marginLeft: 8}}>使用回饋金</div>
              <div style={{color: '#e22828'}}>
                -NT$ {getFormatPrice(totalUsedPoints)}
              </div>
              <div
                style={{
                  marginLeft: 10,
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={openCreditPanel}>
                <SvgIcon.DropDown
                  w={20}
                  h={20}
                  color={'rgba(20, 20, 20, 0.7)'}
                />
              </div>
            </FlexRow>

            <FlexRow>
              <div style={{fontSize: 12, color: Color.red, fontWeight: 500}}>
                敬請注意 :
                經折抵使用之回饋金，將在退貨退款完成後返回，且將有三天使用期限。
              </div>
            </FlexRow>
          </div>
        )}

        <div className="price">
          <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <div style={{fontSize: 12, fontWeight: 'bold'}}>總金額</div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{fontSize: 16}}>
                NT {getFormatPrice(cart.price_detail.price)}
              </div>
              <div
                onClick={() => openDetailModal()}
                style={{
                  fontSize: 12,
                  color: 'rgba(20, 20, 20, 0.7)',
                  marginLeft: 10,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                明細{' '}
                <SvgIcon.DropDown
                  w={20}
                  h={20}
                  color={'rgba(20, 20, 20, 0.7)'}
                />
              </div>
            </div>
          </div>

          <RectButton
            isDisabled={disabledCheckout || disabledCheckoutByOverMax}
            isLoading={loading}
            text="結帳"
            skin={BUTTON_SKIN.PRIMARY}
            onClick={onConfirm}
          />
        </div>
      </MobileWrapper>
    );
  }

  return (
    <Wrapper>
      <h2 style={{marginBottom: 16}}>支付明細</h2>

      <PriceDetail
        openCreditPanel={openCreditPanel}
        values={values}
        setValues={setValues}
        cart={cart}
        mobile={smallDevice}
        isShowUseGiveBackBar={isShowUseGiveBackBar}
        totalUsedPoints={totalUsedPoints}
        onTogglePoint={onTogglePoint}
      />

      <GiveBack style={{margin: '20px 0px'}} price={cart.price_detail.price} />

      <RectButton
        isDisabled={disabledCheckout || disabledCheckoutByOverMax}
        isLoading={loading}
        text={isCheckout ? '結帳' : '繼續結帳'}
        skin={BUTTON_SKIN.PRIMARY}
        style={{width: '100%'}}
        onClick={onConfirm}
      />

      {isCheckout && (
        <div
          style={{
            color: Color.mainDark_70,
            fontWeight: 300,
            fontSize: 12,
            lineHeight: 1.67,
            marginTop: 12,
          }}>
          點擊按鈕，即表示您同意我們的{' '}
          <UnderlineButton
            onClick={() => {
              Actions.setGlobalModalContent(
                <Alert icon={null} title="會員條款" cancelLabel="確認">
                  <div style={{maxHeight: 400, overflowY: 'auto'}}>
                    {TermsOfOnlineTransactionSections.map((s, index) => (
                      <section key={index} className="section">
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            margin: '8px 0',
                          }}
                          className="title">
                          {s.title}
                        </div>
                        {s.data.map((c, idx) => (
                          <div key={idx} className="content">
                            {c}
                          </div>
                        ))}
                      </section>
                    ))}
                  </div>
                </Alert>,
              );
            }}
            style={{
              color: Color.mainDark_70,
              fontWeight: 300,
              fontSize: 12,
              lineHeight: 1.67,
              padding: 0,
            }}>
            使用條款
          </UnderlineButton>{' '}
          與{' '}
          <UnderlineButton
            onClick={() => {
              Actions.setGlobalModalContent(
                <Alert icon={null} title="隱私權政策" cancelLabel="確認">
                  <div style={{maxHeight: 400, overflowY: 'auto'}}>
                    {PrivacyPolicySections.map((s, index) => (
                      <section key={index} className="section">
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            margin: '8px 0',
                          }}
                          className="title">
                          {s.title}
                        </div>
                        {s.data.map((c, idx) => (
                          <div key={idx} className="content">
                            {c}
                          </div>
                        ))}
                      </section>
                    ))}
                  </div>
                </Alert>,
              );
            }}
            style={{
              color: Color.mainDark_70,
              fontWeight: 300,
              fontSize: 12,
              lineHeight: 1.67,
              padding: 0,
            }}>
            隱私權政策
          </UnderlineButton>{' '}
          ，其中包括辦理退貨時，TUN 會代為處理發票及折讓單，以加速退款。
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 2px;
  background-color: rgba(20, 20, 20, 0.03);
  color: #141414;

  & > h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  }
`;

const MobileWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: white;
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);

  & > .discount {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  & > .price {
    border-top: 1px solid rgba(20, 20, 20, 0.1);
    padding: 12px 20px;
    display: flex;
    align-items: center;
  }
`;

function PriceDetail(props) {
  const {
    cart,
    openCreditPanel,
    mobile,
    onTogglePoint,
    totalUsedPoints,
    isShowUseGiveBackBar,
  } = props;
  const {subtotal, price_detail} = cart || {};
  const app = useContext(Context);

  if (!cart) {
    return null;
  }

  return (
    <PriceDetailWrapper>
      <div className="price-row">
        <div className="label">原價小計</div>
        <div className="price">
          NT {getFormatPrice(getOriginPriceSubtotal(cart.items))}
        </div>
      </div>
      <div className="price-row">
        <div className="label">小計</div>
        <div className="price">NT {getFormatPrice(subtotal)}</div>
      </div>

      <div className="price-row">
        <div className="label">運費</div>
        <div className="price">NT {getFormatPrice(0)}</div>
      </div>

      {price_detail.target_price_discount_value !== 0 && (
        <div className="price-row">
          <div className="label">
            {L.d(price_detail.target_price_discount_title)}
          </div>
          <div className="price red">
            - NT {getFormatPrice(price_detail.target_price_discount_value)}
          </div>
        </div>
      )}

      {!mobile && isShowUseGiveBackBar && (
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <FlexRow
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 5,
            }}>
            <FlexRow>
              <button
                style={{
                  width: 20,
                  height: 20,
                  outline: 'none',
                  border: '1px solid rgba(20,20,20,0.7)',
                  backgroundColor: app.isUsePoints ? '#000' : '#fff',
                  color: '#fff',
                  marginRight: 8,
                  cursor: 'pointer',
                }}
                onClick={onTogglePoint}>
                ✓
              </button>
              <div style={{marginRight: 8}}>使用回饋金</div>
              <UnderlineButton
                style={{fontSize: 12, color: 'rgba(20,20,20,0.7)'}}
                onClick={openCreditPanel}>
                編輯折抵金額
              </UnderlineButton>
            </FlexRow>
            <div style={{color: Color.red, fontSize: 14}}>
              - NT {getFormatPrice(totalUsedPoints)}
            </div>
          </FlexRow>
          <FlexRow>
            <div style={{fontSize: 12, color: Color.red, fontWeight: 500}}>
              敬請注意 :
              經折抵使用之回饋金，將在退貨退款完成後返回，且將有三天使用期限。
            </div>
          </FlexRow>
        </div>
      )}

      {mobile && isShowUseGiveBackBar && totalUsedPoints !== 0 && (
        <div className="price-row">
          <div className="label">使用回饋金</div>
          <div className="price red">
            - NT {getFormatPrice(totalUsedPoints)}
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: 'rgba(20, 20, 20, 0.1)',
          height: 1,
          margin: '20px 0',
        }}
      />
      <div className="total-price">
        <div className="label">總金額</div>
        <div className="price">NT {getFormatPrice(price_detail.price)}</div>
      </div>
    </PriceDetailWrapper>
  );
}

const PriceDetailWrapper = styled.div`
  & > .price-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & > .label {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.57;
      color: rgba(20, 20, 20, 0.7);
      flex: 1;
    }

    & > .price {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
    }

    & > .price.red {
      color: #e22828;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > .total-price {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > .label {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.57;
      margin-right: 8px;
    }

    & > .price {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4;
    }
  }
`;

function DetailModalContent(props) {
  const {
    onClose,
    mobile,
    cart,
    isShowUseGiveBackBar,
    totalUsedPoints,
    onTogglePoint,
  } = props;
  const app = React.useContext(AppContext.Context);

  return (
    <DetailModalContentWrapper>
      <div className="header" style={{marginBottom: 16}}>
        <h2>支付明細</h2>
        <div className="close" onClick={onClose}>
          <SvgIcon.Cross w={24} h={24} />
        </div>
      </div>

      <PriceDetail
        mobile={mobile}
        cart={app.cart}
        isShowUseGiveBackBar={isShowUseGiveBackBar}
        totalUsedPoints={totalUsedPoints}
        onTogglePoint={onTogglePoint}
      />

      <GiveBack style={{marginTop: 30}} price={cart.price_detail.price} />
    </DetailModalContentWrapper>
  );
}

const DetailModalContentWrapper = styled.div`
  margin: 8px;
  border-radius: 2px;
  background-color: white;
  padding: 12px 20px;

  & > .header {
    display: flex;
    align-items: center;
    position: relative;

    & > h2 {
      flex: 1;
      text-align: center;
      font-size: 16px;
    }

    & > .close {
      position: absolute;
      cursor: pointer;
      right: 0;
    }
  }
`;

export default CheckoutPanel;
