import {
  Color,
  FlexCol,
  FlexRow,
  FlexRowCenter,
  SeparateLine,
} from '../../Components/Widget';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Cross} from '../../Components/SvgIcon';
import {Actions, Context} from '../../Contexts/AppContext';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import styled from 'styled-components';
import {getFormatPrice} from '../../Utils/ProductUtil';
import {CREDIT_TYPE} from '../../Domain/Checkout';
import {summaryOfPoints} from '../../Utils/appLogic';
import * as SvgIcon from '../../Components/SvgIcon';
import * as moment from 'moment';

const CreditPanel = props => {
  const {mobile, onClose, subtotal, discount} = props;
  const app = useContext(Context);
  const {useBirthGiftPoints, useRebatePoints} = app.points;
  const [preCalculatePrice, setPreCalculatePrice] = useState(null);

  const [points, setPoints] = useState({
    birth_gift_points: useBirthGiftPoints,
    rebate_points: useRebatePoints,
  });

  useEffect(() => {
    if (preCalculatePrice) {
      return;
    }
    const init = async () => {
      const _preCalculatePrice = await Actions.setPoints({
        useBirthGiftPoints: points.birth_gift_points,
        useRebatePoints: points.rebate_points,
        isUsePoints: true,
        isPreCalculate: !app.isUsePoints,
      });
      setPreCalculatePrice(_preCalculatePrice.price_detail.price);
    };
    init();
  }, [
    app.isUsePoints,
    points.birth_gift_points,
    points.rebate_points,
    preCalculatePrice,
  ]);

  const onConfirm = async () => {
    await Actions.setIsUsePoints(true);
    onClose();
  };

  const totalUsed = points.birth_gift_points + points.rebate_points;

  return (
    <CreditPanelWrapper mobile={mobile}>
      <FlexCol className="top">
        {!mobile && (
          <Fragment>
            <FlexRow>
              <FlexRow style={{flex: 1}} />
              <Cross style={{cursor: 'pointer'}} onClick={onClose} />
            </FlexRow>
            <FlexRowCenter className="title">使用購物金</FlexRowCenter>
          </Fragment>
        )}
        {mobile && (
          <FlexRow style={{position: 'relative', justifyContent: 'center'}}>
            <Cross
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                cursor: 'pointer',
              }}
              onClick={onClose}
            />
            <h2 className="title">使用購物金</h2>
          </FlexRow>
        )}
        <CreditEditBlock
          mobile={mobile}
          type={'birthGift'}
          points={points}
          maxPriceLimit={subtotal - discount}
          setPoints={setPoints}
          style={
            mobile
              ? {marginTop: '12px', marginBottom: '10px'}
              : {marginTop: '24px', marginBottom: '20px'}
          }
          setPreCalculatePrice={setPreCalculatePrice}
        />
        <CreditEditBlock
          points={points}
          setPoints={setPoints}
          type={'rebate'}
          maxPriceLimit={subtotal - discount}
          mobile={mobile}
          setPreCalculatePrice={setPreCalculatePrice}
        />
      </FlexCol>
      <FlexCol className="bottom">
        <SeparateLine style={{margin: 0}} />
        <FlexRow
          style={{
            padding: mobile ? '10px' : '20px',
            justifyContent: 'space-between',
          }}>
          <FlexCol>
            <h5 className="use-credit" style={{color: 'rgba(20,20,20,0.7)'}}>
              使用折抵 {getFormatPrice(totalUsed)}
            </h5>
            <h4 className="price">
              折抵後金額 {getFormatPrice(preCalculatePrice)}
            </h4>
          </FlexCol>
          <RectButton
            onClick={onConfirm}
            skin={BUTTON_SKIN.PRIMARY}
            text="確定"
          />
        </FlexRow>
      </FlexCol>
    </CreditPanelWrapper>
  );
};

const CreditEditBlock = props => {
  const {
    mobile,
    type = CREDIT_TYPE.birthGift,
    points,
    setPoints,
    style = {},
    maxPriceLimit = 0,
    setPreCalculatePrice,
  } = props;
  const app = useContext(Context);
  const [disabled, setDisabled] = useState(false);

  const DEFAULT_BIRTH_GIFT_UNIT = 500;
  const DEFAULT_REBATE_UNIT = 1000;

  const getDefaultUnit = type =>
    type === CREDIT_TYPE.birthGift
      ? DEFAULT_BIRTH_GIFT_UNIT
      : DEFAULT_REBATE_UNIT;

  const getHeaderTitle = type =>
    type === CREDIT_TYPE.birthGift ? '生日禮金' : '會員回饋';

  const getRemains = type =>
    type === CREDIT_TYPE.birthGift
      ? summaryOfPoints(app.currentUser.birth_gift_points)
      : summaryOfPoints(app.currentUser.rebate_points);

  const getUsedPoints = type =>
    type === CREDIT_TYPE.birthGift
      ? points.birth_gift_points
      : points.rebate_points;

  const onPlus = {
    [CREDIT_TYPE.birthGift]: async () => {
      setDisabled(true);
      const nextBirthGift = points.birth_gift_points + getDefaultUnit(type);
      if (nextBirthGift > getRemains(CREDIT_TYPE.birthGift)) {
        return;
      }

      if (nextBirthGift > maxPriceLimit - points.rebate_points) {
        return;
      }

      const _preCalculatePrice = await Actions.setPoints({
        useBirthGiftPoints: nextBirthGift,
        useRebatePoints: points.rebate_points,
        isUsePoints: true,
        isPreCalculate: !app.isUsePoints,
      });
      setPreCalculatePrice(_preCalculatePrice.price_detail.price);
      setPoints({...points, birth_gift_points: nextBirthGift});
      setDisabled(false);
    },
    [CREDIT_TYPE.rebate]: async () => {
      setDisabled(true);
      const nextRebate = points.rebate_points + getDefaultUnit(type);
      if (nextRebate > getRemains(CREDIT_TYPE.rebate)) {
        return;
      }
      if (nextRebate > maxPriceLimit - points.birth_gift_points) {
        return;
      }

      const _preCalculatePrice = await Actions.setPoints({
        useBirthGiftPoints: points.birth_gift_points,
        useRebatePoints: nextRebate,
        isUsePoints: true,
        isPreCalculate: !app.isUsePoints,
      });

      setPreCalculatePrice(_preCalculatePrice.price_detail.price);
      setPoints({...points, rebate_points: nextRebate});
      setDisabled(false);
    },
  };

  const onMinus = {
    [CREDIT_TYPE.birthGift]: async () => {
      setDisabled(true);
      const nextBirthGift = points.birth_gift_points - getDefaultUnit(type);
      if (nextBirthGift < 0) {
        return;
      }

      const _preCalculatePrice = await Actions.setPoints({
        useBirthGiftPoints: nextBirthGift,
        useRebatePoints: points.rebate_points,
        isUsePoints: true,
        isPreCalculate: !app.isUsePoints,
      });

      setPreCalculatePrice(_preCalculatePrice.price_detail.price);

      setPoints({...points, birth_gift_points: nextBirthGift});
      setDisabled(false);
    },
    [CREDIT_TYPE.rebate]: async () => {
      setDisabled(true);
      const nextRebate = points.rebate_points - getDefaultUnit(type);
      if (nextRebate < 0) {
        return;
      }

      const _preCalculatePrice = await Actions.setPoints({
        useBirthGiftPoints: points.birth_gift_points,
        useRebatePoints: nextRebate,
        isUsePoints: true,
        isPreCalculate: !app.isUsePoints,
      });

      setPreCalculatePrice(_preCalculatePrice.price_detail.price);
      setPoints({...points, rebate_points: nextRebate});
      setDisabled(false);
    },
  };

  const plusDisabled =
    type === CREDIT_TYPE.birthGift
      ? points.birth_gift_points + getDefaultUnit(type) >
          getRemains(CREDIT_TYPE.birthGift) ||
        points.birth_gift_points + getDefaultUnit(type) >
          maxPriceLimit - points.rebate_points
      : points.rebate_points + getDefaultUnit(type) >
          getRemains(CREDIT_TYPE.rebate) ||
        points.rebate_points + getDefaultUnit(type) >
          maxPriceLimit - points.birth_gift_points;

  const minusDisabled =
    type === CREDIT_TYPE.birthGift
      ? points.birth_gift_points - getDefaultUnit(type) < 0
      : points.rebate_points - getDefaultUnit(type) < 0;

  return (
    <CreditEditBlockWrapper mobile={mobile} style={style} className="block">
      <FlexCol className="header">
        <FlexRow
          style={{fontSize: 20, fontWeight: 'bold', alignItems: 'center'}}
          className="header-title">
          <SvgIcon.Square color={'white'} />
          {getHeaderTitle(type)}
          <SvgIcon.Square color={'white'} />
        </FlexRow>
        <p className="header-hint">
          餘額 NT {getFormatPrice(getRemains(type))}，使用時需以
          {getFormatPrice(getDefaultUnit(type))}
          為單位
        </p>
      </FlexCol>
      <FlexCol className="content">
        <FlexRow className="counter">
          <button
            disabled={minusDisabled || disabled}
            className="minus"
            onClick={onMinus[type]}>
            - {getFormatPrice(getDefaultUnit(type))}
          </button>
          <h2 className="amount">{getFormatPrice(getUsedPoints(type))}</h2>
          <button
            disabled={plusDisabled || disabled}
            className="plus"
            onClick={onPlus[type]}>
            + {getFormatPrice(getDefaultUnit(type))}
          </button>
        </FlexRow>
        {type === CREDIT_TYPE.birthGift && (
          <p className="content-hint">限生日當月使用</p>
        )}
        <div className="expiration_data_list">
          {type === CREDIT_TYPE.birthGift &&
            app.currentUser.birth_gift_points.map(p => (
              <p className="content-hint danger">
                {getFormatPrice(p.point)} 將在{' '}
                {moment(p.expiration_date).format('YYYY/MM/DD')} 到期
              </p>
            ))}

          {type === CREDIT_TYPE.rebate &&
            app.currentUser.rebate_points.map(p => (
              <p className="content-hint danger">
                {getFormatPrice(p.point)} 將在{' '}
                {moment(p.expiration_date).format('YYYY/MM/DD')} 到期
              </p>
            ))}
        </div>
      </FlexCol>
    </CreditEditBlockWrapper>
  );
};

const CreditEditBlockWrapper = styled.div`
  box-shadow: 0 0 10px #ddd;
  border-radius: 2px;
  & > .header {
    border-radius: 2px;
    color: white;
    white-space: nowrap;
    padding: 16px ${props => (props.mobile ? '36px' : '58px')};
    background-color: #cca75c;
    justify-content: center;
    align-items: center;
    & > h2 {
      color: white;
    }
  }
  & > .content {
    align-items: center;
    padding-bottom: ${props => (props.mobile ? '12px' : '24px')};
    & > .counter {
      width: 90%;
      margin-top: ${props => (props.mobile ? '16px' : '24px')};
      margin-bottom: 12px;
      border: 1px solid rgba(20, 20, 20, 0.1);
      & button {
        padding: 15px 10px;
        border: none;
        cursor: pointer;
        outline: none;
        background: rgba(20, 20, 20, 0.03);
        :disabled {
          filter: opacity(0.5);
        }
      }
      & .amount {
        flex: 1;
        text-align: center;
        font-weight: bold;
      }
    }
    & > .content-hint {
      color: #cca75c;
    }
    & > .expiration_data_list {
      max-height: 50px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & > .content-hint.danger {
        color: ${Color.red};
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;

const CreditPanelWrapper = styled.div`
  background-color: #fff;
  margin: ${props => (props.mobile ? '8px' : '0')};
  font-size: ${props => (props.mobile ? '12px' : '14px')};

  & .top {
    padding: 24px;
  }

  & .top > .title {
    font-weight: bold;
    font-size: 20px;
  }
`;

export default CreditPanel;
