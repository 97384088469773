import React from 'react';
import styled from 'styled-components';
import RectButton, {BUTTON_SKIN} from '../../Components/RectButton';
import {Color, UnderlineButton} from '../../Components/Widget';
import {Actions} from '../../Contexts/AppContext';
import Alert from '../../Components/Modal/Alert';
import {PrivacyPolicySections} from '../../Domain/Help';

function Support(props) {
  const {mobile, style = {}} = props;
  return (
    <Wrapper style={style}>
      {/*// FIXME: @guychienll future feature comment first*/}
      {/*<RectButton*/}
      {/*  text="聯絡線上客服"*/}
      {/*  skin={BUTTON_SKIN.DEFAULT}*/}
      {/*  style={{width: '100%', marginBottom: 8}}*/}
      {/*  onClick={() => {*/}
      {/*    window.open('tel:0800336677', '_self');*/}
      {/*  }}*/}
      {/*/>*/}

      <p>
        您可以在星期一至星期五，9:00 至 18:00 聯絡線上客服，或撥打客服專線
        <a rel="noreferrer" href="tel:0800336677">
          <span style={{color: Color.mainDark_70, textDecoration: 'underline'}}>
            0800-336-677
          </span>
        </a>
        。
      </p>

      <h3>免費退換貨</h3>
      <p>享有 免費運送 與 7 天內 免費退貨。</p>

      <h3>安全購物</h3>
      <p>
        我們採用 SSL
        安全憑證技術，以保障您在線上的信用卡交易安全，如欲完整查看請參閱我們的{' '}
        <UnderlineButton
          onClick={() => {
            Actions.setGlobalModalContent(
              <Alert icon={null} title="隱私權政策" cancelLabel="確認">
                <div style={{maxHeight: 400, overflowY: 'auto'}}>
                  {PrivacyPolicySections.map((s, index) => (
                    <section key={index} className="section">
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          margin: '8px 0',
                        }}
                        className="title">
                        {s.title}
                      </div>
                      {s.data.map((c, idx) => (
                        <div key={idx} className="content">
                          {c}
                        </div>
                      ))}
                    </section>
                  ))}
                </div>
              </Alert>,
            );
          }}
          style={{
            color: Color.mainDark_70,
            fontWeight: 300,
            fontSize: 12,
            lineHeight: 1.67,
            padding: 0,
          }}>
          隱私權政策
        </UnderlineButton>
        。
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 2px;
  color: #141414;
  border: solid 1px rgba(20, 20, 20, 0.1);

  & > h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    margin: 32px 0 0 0;
  }

  & > p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.57;
    color: rgba(20, 20, 20, 0.7);
  }
`;

export default Support;
