const isAllowCvsLogistic = ({totalPrice = 0, itemsOfBrands = []}) => {
  const totalLimit = 100000;
  const subOrderTotalLimit = 20000;
  if (totalPrice >= totalLimit) {
    return false;
  } else if (
    itemsOfBrands.some(
      items =>
        items.reduce((acc, item) => acc + item.price * item.quantity, 0) >=
        subOrderTotalLimit,
    )
  ) {
    return false;
  }

  return true;
};

const summaryOfPoints = list => {
  if (!Array.isArray(list)) {
    return 0;
  }
  const summaryPoints = list.reduce((acc, cur) => {
    acc += cur.point;
    return acc;
  }, 0);

  return summaryPoints < 0 ? 0 : summaryPoints;
};

const getIsShowUseGiveBackBar = ({rebatePoints = [], birthGiftPoints = []}) => {
  return (
    Math.floor(summaryOfPoints(rebatePoints) / 1000) +
      Math.floor(summaryOfPoints(birthGiftPoints) / 500) >
    0
  );
};

export {isAllowCvsLogistic, summaryOfPoints, getIsShowUseGiveBackBar};
