import {Color} from './Widget';
import React from 'react';
import styled from 'styled-components';

const Checkbox = props => {
  const {onChange, id, checked, style = {}, size = 20} = props;
  return (
    <Wrapper checked={checked} style={style} size={size}>
      <input
        onChange={onChange}
        value={id}
        id={id}
        type="checkbox"
        style={{display: 'none'}}
      />
      <label htmlFor={id} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > label {
    display: block;
    width: ${({size}) => `${size}px`};
    height: ${({size}) => `${size}px`};
    border: ${props =>
      props.checked ? 'none' : `1px solid ${Color.mainDark_30}`};
    background-color: ${props =>
      props.checked ? Color.mainDark : Color.mainWhite};
    position: relative;
    &::after {
      content: '✓';
      display: ${props => (props.checked ? 'block' : 'none')};
      color: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default Checkbox;
