import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Actions, Context} from '../../Contexts/AppContext';
import {getMemberLevelData, getPromoData} from '../../Utils/PromotionsUtil';
import * as L from '../../Utils/Lang';
import {getFormatPrice} from '../../Utils/ProductUtil';
import {MEMBERSHIP_LEVEL_DISPLAY} from '../../Domain/Member';
import {Color} from '../../Components/Widget';

function GiveBackPromo(props) {
  const {mobile, priceDetail} = props;
  const [promoData, setPromoData] = React.useState(null);
  const [memberLevelData, setMemberLevelData] = React.useState(null);
  const app = React.useContext(Context);
  const user = app.currentUser;

  React.useEffect(() => {
    const _fetchData = async () => {
      let _promotions = await Actions.getPromotions();
      let data = getPromoData({
        price: priceDetail.subtotal,
        promotions: _promotions,
      });
      setPromoData(data);
    };
    _fetchData();
  }, [priceDetail.subtotal, setPromoData]);

  const [orderHistories, setOrderHistories] = React.useState(null);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    const fetchOrderHistories = async () => {
      const resp = await Actions.fetchOrderHistories();
      setOrderHistories(resp);
    };
    fetchOrderHistories().then(() => 0);
  }, [user]);

  const grandTotalHistories = useMemo(() => {
    if (!orderHistories) {
      return 0;
    }
    return orderHistories.histories.reduce((acc, cur) => {
      acc += cur.price;
      return acc;
    }, 0);
  }, [orderHistories]);

  React.useEffect(() => {
    if (user) {
      let data = getMemberLevelData({
        price: priceDetail.price,
        level: user.vip_level,
        grandTotal: grandTotalHistories,
      });
      setMemberLevelData(data);
    }
  }, [user, setMemberLevelData, priceDetail.price, grandTotalHistories]);

  if (!promoData?.shouldShowNext && !memberLevelData?.shouldShowNext) {
    return null;
  }

  return (
    <Wrapper>
      {!mobile &&
        (promoData?.shouldShowNext || memberLevelData?.shouldShowNext) && (
          <h2 style={{marginBottom: 16}}>還差一點，即享優惠回饋</h2>
        )}

      {promoData?.shouldShowNext && (
        <div className="line">
          <div className="type">折扣</div>
          <div>
            差{' '}
            <span style={{color: Color.red}}>
              {getFormatPrice(promoData.diffAmount)}
            </span>{' '}
            <span>即符合 </span>
            <b>{L.d(promoData.next.title)}</b>
          </div>
        </div>
      )}

      {memberLevelData?.shouldShowNext && (
        <div className="line">
          <div className="type">回饋</div>
          <div>
            差{' '}
            <span style={{color: 'red'}}>
              {getFormatPrice(memberLevelData.diffAmount)}
            </span>{' '}
            升級{L.d(MEMBERSHIP_LEVEL_DISPLAY[memberLevelData.next.key])}
            ，領升等回饋
            <b>{getFormatPrice(memberLevelData.next.rewards)}</b>
          </div>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  background-color: rgba(204, 167, 92, 0.08);
  white-space: nowrap;

  & > h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
  }

  & > .line {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 12px;

    & > .type {
      margin: 0 12px 0 0;
      padding: 0 8px;
      border: solid 1px #cca75c;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.83;
      color: #cca75c;
      white-space: nowrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default GiveBackPromo;
