import {ChevronLeft} from './SvgIcon';
import React from 'react';

const BackButton = props => {
  const {title, onClick} = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}>
      <ChevronLeft />
      <span style={{whiteSpace: 'nowrap'}}>{title}</span>
    </div>
  );
};

export default BackButton;
